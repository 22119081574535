//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.dummy-picture-container {
	position: relative;
	left: 40%;
	width: 150px;
	height: 150px;
  }
  
  .dummy-picture-container::before {
	content: '+';
	position: absolute;
	top: 80%;
	left:10%;
	transform: translate(-50%, -50%);
	font-size: 30px;
 
	color: #ffffff;
	background-color: #a09e9e;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
  }
  .hover-white-text {
	color: white; /* Change the text color to white on hover */

	transition: color 0.3s; /* Add a transition for smooth color change */
  
	/* Define styles for when hovering */
	&:hover {
		border: #ffffff solid 1px;
	color: rgb(255, 255, 255); /* Set the initial text color */

	}
  }